<template>
	<div id="ag-grid-demo">
		<vx-card id="email-grid-loader" class="vs-con-loading__container">
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowData="tableData"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'ID',
					field: 'id',
					minWidth: 100,
					filter: "agTextColumnFilter",
					pinned: 'left',
					hide: true,
				},
				{
					headerName: 'Registrant Email',
					field: 'registrant_email',
					minWidth: 350,
					filter: "agTextColumnFilter",
					pinned: 'left',
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				],
				tableData: null,
				deleteNode: {
					id: [],
				}
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#email-grid-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#email-grid-loader > .con-vs-loading");
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Delete the selected email(s)?',
					accept: this.acceptDeleteAlert
				});
			},
			acceptDeleteAlert() {
				let self = this;
				// Show loader
				this.showDivLoading();
				// Post Axios Call
				this.$axiosSecure.post('/delete-blacklisted-registrant-email', {
					...self.deleteNode,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Email delete success',
						text: 'The selected email(s) has been deleted'
					});
					
					// Table Data AXIOS Call
					this.populateTableData('loader');
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			populateTableData(loader) {
				let self = this;

				this.$axiosSecure.get('/get-blacklisted-registrant-emails-json')
				.then((response) => {
					this.tableData = response.data;
				
					if(loader == 'loader') {
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this,
					rangeSelection = this.gridApi.getCellRanges(),
					singleCell = false;

				// Reset Deactivate Node ID
				this.deleteNode['id'] = [];

				if(rangeSelection) {
					// Get selected cells & iterate through them to find rules
					rangeSelection.forEach(item => {
						let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
							end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
							isRightClicked = false;
						
						for (let i = start; i <= end; i++) {
							if(self.gridApi.getDisplayedRowAtIndex(i)['data']['id'] == params['node']['data']['id']) {
								isRightClicked = true;
								singleCell = true;
							}
							self.deleteNode['id'].push(self.gridApi.getDisplayedRowAtIndex(i)['data']['id']);
						}
						
						if(!isRightClicked) {
							self.deleteNode['id'].push(params['node']['data']['id']);
						}
					});

					// If only one cell is selected
					if(rangeSelection.length == 1 && singleCell && rangeSelection[0]['start']['rowIndex'] == rangeSelection[0]['end']['rowIndex']) {
						result = [
							'copy',
							'copyWithHeaders',
							'paste',
							'separator',
							'export',
							'separator',
							{
								name: "Delete",
								action: () => {
									this.openConfirm();
								},
							},
						];
					}
					else {
						result = [
							'copy',
							'copyWithHeaders',
							'paste',
							'separator',
							'export',
							'separator',
							{
								name: "Bulk Delete",
								action: () => {
									this.openConfirm();
								},
							},
						];
					}
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export',
						'separator',
						{
							name: "Delete",
							action: () => {
								self.deleteNode['id'].push(params['node']['data']['id']);
								this.openConfirm();
							},
						},
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);
		},
	}

</script>