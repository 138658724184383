<template>
	<div id="ag-grid-demo">
		<div class="vx-row">
			<div class="vx-col w-full xl:w-1/2 mb-base">
				<!-- Blacklist Registrant Email -->
				<blacklist-registrant-email />
			</div>
			<div class="vx-col w-full xl:w-1/2 mb-base">
				<!-- Blacklist Registrant Phone -->
				<blacklist-registrant-phone />
			</div>
		</div>
	</div>
</template>

<script>
	import BlacklistRegistrantEmail from '@/components/ag-grid-tables/BlacklistRegistrantEmail.vue'
	import BlacklistRegistrantPhone from '@/components/ag-grid-tables/BlacklistRegistrantPhone.vue'

	export default {
		components: {
			BlacklistRegistrantEmail,
			BlacklistRegistrantPhone,
		},
		data() {
			return {
				
			}
		},
	}

</script>